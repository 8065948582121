import React, { useState } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField';
import { LoadingBar, Table, ConfirmDialog, showMessage } from '../common';
import { useFetch } from '../common/hooks';
import { fetchWrap as fetch, getLangValue } from '../common/functions';
import config from '../config';

const filterDuplicates = (list) => {
    const newList = [];
    for (const item of list) {
        if (newList.find((existing) => existing.product_id === item.product_id) == null) {
            newList.push(item);
        }
    }
    return newList;
};

const download = (lottery_id, item, name, setPrintMap, printMap, format, count=null, freeText=null) => {
    let mimetype = null;

    if(format === 'pdf' || format === 'omaposti-pro') {
        mimetype = 'application/pdf';
    } else if(format === 'csv' || format === 'consignor') {
        mimetype = 'text/csv';
    } else {
        return;
    }
    fetch(`${config.backendAddress}/api/v1/lotteries/${lottery_id}/products/${item.product_id}/unprinted?format=${format}${count ? '&count=' + count : ''}${freeText ? '&freetext=' + freeText : ''}`)
        .then((response) => {
            setPrintMap({ ...printMap, [item.product_id]: response.headers.get('arpamaailma-product-print-token') });
            return response;
        })
        .then((response) => response.blob())
        .then((blob) => {
            const newBlob = new Blob([blob], { type: mimetype });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = data;
            link.download = `${name}_${moment().format()}.${format}`;
            if (format === 'consignor') {
                link.download += '.csv';
            }
            if (format === 'omaposti-pro') {
                link.download += '.pdf';
            }
            link.click();
            setTimeout(function(){
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        })
        .catch((error) => {
            console.error(error);
        });
};

const Print = ({ location }) => {
    const values = queryString.parse(location.search);
    const [{ data, loading, refresh }] = useFetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/products/stats`);
    const [printMap, setPrintMap] = useState({});
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(false);
    const [printPostiDialogOpen, setPrintPostiDialogOpen] = useState(false);
    const [count, setCount] = useState(10);
    const [freeText, setFreeText] = useState('');
    const [postiItem, setPostiItem] = useState(null);

    const handleClose = () => {
        setCount(10);
        setFreeText('');
        setPostiItem(null);
        setPrintPostiDialogOpen(false);
    };

    return (
        <>
            <LoadingBar in={loading} />
            <Container>
                <Toolbar disableGutters>
                    <Typography
                        variant='h4'
                        noWrap
                        style={{ flexGrow: 1 }}
                    >
                        Tulosta
                    </Typography>
                </Toolbar>
                <Table
                    idKey='product_id'
                    data={data ? filterDuplicates(data.filter((item) => item.product_id)) : []}
                    columns={[
                        { label: 'Nimi', key: 'product_names', callback: (value) => getLangValue(value, 'fi') },
                        { label: 'Tulostamatta', key: 'unprinted' }
                    ]}
                    rowActions={(item) => (
                        <>
                            {item.unprinted >= 0 && (
                                <>
                                    <Button variant='contained' color='primary' style={{ margin: '0 1em' }} onClick={() => {
                                        setPostiItem(item);
                                        setPrintPostiDialogOpen(true);
                                    }}>Lataa Posti-PDF</Button>
                                    <Button variant='contained' color='primary' style={{ margin: '0 1em' }} onClick={() => download(values.lottery, item, getLangValue(item.product_names, 'fi'), setPrintMap, printMap, 'csv')}>Lataa CSV</Button>
                                    <Button variant='contained' color='primary' style={{ margin: '0 1em' }} onClick={() => download(values.lottery, item, getLangValue(item.product_names, 'fi'), setPrintMap, printMap, 'consignor')}>Lataa Consignor CSV</Button>
                                    <Button variant='contained' color='primary' style={{ margin: '0 1em' }} onClick={() => download(values.lottery, item, getLangValue(item.product_names, 'fi'), setPrintMap, printMap, 'pdf')}>Lataa PDF</Button>
                                    <Button variant='contained' color='primary' disabled={printMap[item.product_id] == null} onClick={() => {
                                        setSelectedProduct(item);
                                        setConfirmDialogOpen(true);
                                    }} style={{ margin: '0 1em' }}>Merkitse tulostetuksi</Button>
                                </>
                            )}
                        </>
                    )}
                />
            </Container>
            <ConfirmDialog
                open={confirmDialogOpen}
                title='Merkitse tulostetuksi'
                text='Haluatko varmasti merkitä tuotteen tulostetuksi?'
                confirmText='Merkitse'
                onCancel={() => setConfirmDialogOpen(false)}
                onConfirm={() => {
                    fetch(`${config.backendAddress}/api/v1/lotteries/${values.lottery}/products/${selectedProduct.product_id}/unprinted`, {
                        method: 'PUT',
                        headers: {
                            'arpamaailma-product-print-token': printMap[selectedProduct.product_id]
                        }
                    })
                        .then((response) => {
                            showMessage('Tuote merkitty tulostetuksi');
                            setConfirmDialogOpen(false);
                            refresh();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
            />
            <Dialog
                open={printPostiDialogOpen}
                onClose={() => handleClose()}
            >
                <DialogTitle>Lataa pakettikortteja</DialogTitle>
                <DialogContent>
                    <TextField
                        id='freetext'
                        name='freetext'
                        label='Lisätedot'
                        value={freeText}
                        onChange={(event) => setFreeText(event.target.value)}
                    />
                    <TextField
                        id='count'
                        label='määrä'
                        type='number'
                        value={count}
                        onChange={(event) => setCount(event.target.value)}
                        inputProps={{
                            min: 0,
                            max: 30,
                            type: 'number'
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' style={{ margin: '0 1em' }} onClick={() => handleClose()}>Peruuta</Button>
                    <Button variant='contained' color='primary' style={{ margin: '0 1em' }} onClick={() => {
                        download(values.lottery, postiItem, getLangValue(postiItem.product_names, 'fi'), setPrintMap, printMap, 'omaposti-pro', count, freeText);
                        handleClose();
                    }}>Lataa</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Print;
